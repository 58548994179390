// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
// import 'bootstrap/js/dist/scrollspy';
import "bootstrap/js/dist/tab";
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
import * as Sentry from "@sentry/browser";
import { isMatchingPattern } from "@sentry/utils";

function hasFingerprintError(event) {
  const breadcrumbs = event.breadcrumbs;
  if (!breadcrumbs?.length) {
    return false;
  }
  return breadcrumbs.some((breadcrumb) => {
    const args = breadcrumb.data?.arguments;
    if (args?.length) {
      return args.some((arg) =>
        arg.includes("antifingerprint not defined yet"),
      );
    }
    return false;
  });
}

function hasUnsupportedBrowser(event) {
  const headers = event?.request?.headers;
  const userAgent = headers?.["User-Agent"];
  if (!userAgent) {
    return false;
  }
  return (
    // versions of chrome from 60 to 79
    !!userAgent.match(
      /(Android [1-6].[0-9])|(AhrefsBot)|(ahrefs)|(BingPreview)|(DuckDuckGo)/,
    )
  );
}

const initSentry = () => {
  if (window.SENTRY_DSN) {
    Sentry.init({
      dsn: window.SENTRY_DSN,
      ignoreErrors: [
        "ChunkLoadError",
        /Can't find variable: setIOSParameters/,
        /\$ is not defined/,
        "ResizeObserver",
        /__gCrWeb/,
        /getMapInfo/,
        /getGraphInfo/,
        /acsbJS/,
      ],
      denyUrls: [
        /^chrome-extension:\/\//,
        /maps\.googleapis\.com/,
        /connect\.facebook\.net/,
        /ts\.js/,
        /^file:\/\/\//,
        /autocheck/,
        /hyros\.com/,
        /google-analytics\.com/,
        /spincar-static/,
        /gtag\/js/,
        /monitor\/stat/,
        /webkit-masked-url:/,
        /clickcease\.com/,
        /rlets\.com/,
        /impel\.io/,
        /tiktok\.com/,
        /safari-web-extension:/,
      ],
      beforeSend: (event) => {
        // IOS Support: ignore anything from gtm.js.
        const frames = event.exception.values?.[0].stacktrace?.frames || [];
        if (
          frames.some((frame) =>
            isMatchingPattern(
              frame.filename || "",
              /(gtm\.js)|(^chrome-extension:\/\/)/,
            ),
          )
        ) {
          return null;
        }
        if (event?.exception?.values?.length) {
          const e = event.exception.values[0];
          if (
            e.type === "UnhandledRejection" &&
            (!event.breadcrumbs?.length || hasFingerprintError(event))
          ) {
            return null;
          }
          if (hasUnsupportedBrowser(event)) {
            return null;
          }
        }
        return event;
      },
    });
  }
};

const BLOCKS = [
  { name: "map", selector: ".map" },
  { name: "popover", selector: "[data-bs-toggle='popover']" },
];
const initBlocks = () => {
  BLOCKS.forEach((block) => {
    const blockElements = document.querySelectorAll(block.selector);
    if (blockElements.length) {
      import(
        /* webpackChunkName: "blocks", webpackPrefetch: true */ `./blocks/${block.name}`
      ).then(({ render }) => {
        render(blockElements);
      });
    }
  });
};

const initPhones = () => {
  const phones = document.querySelectorAll('input[name="phone"]');
  if (phones.length) {
    import("cleave.js").then(({ default: Cleave }) => {
      document.querySelectorAll('input[name="phone"]').forEach((phoneEl) => {
        new Cleave(phoneEl, {
          delimiters: ["(", ") ", "-"],
          blocks: [0, 3, 3, 4],
          uppercase: true,
        });
      });
    });
  }
};

const initModals = () => {
  const modal = document.querySelector(".modal");
  if (modal) {
    modal.addEventListener("show.bs.modal", (event) => {
      const content = document.querySelector(
        `#${event.relatedTarget.dataset.modalContentId}`,
      );
      const body = modal.querySelector(".modal-body");
      body.innerHTML = content.innerHTML;
    });
  }
};

/**
 *  pageYOffset can be negative on iOS
 *  Bug: iOS user scroll to top and a bit more, page bounced.
 *  We get negative pageYOffset, then page move back.
 *  And we get prevOffset (-10) > newOffset (0)
 */
const getCurrentScrollPosition = () => Math.max(0, window.pageYOffset);

const initHideOnScrolling = () => {
  let scrollOfset = 0;
  const navbar = document.querySelectorAll(
    '.navbar-content[data-hide-on-scroll="true"]',
  );
  if (!navbar.length) {
    return;
  }
  navbar[0].style.transition = "margin 1s";
  const isMobile = () => document.body.clientWidth < 992;
  const hideDefaultHeader = () => {
    const currentScrollPosition = getCurrentScrollPosition();
    navbar.forEach((element) => {
      const hEl = element.getBoundingClientRect().height;
      if (currentScrollPosition >= hEl) {
        element.style.marginTop = `-${hEl}px`;
      }
    });
  };

  const showDefaultHeader = () => {
    navbar.forEach((element) => {
      element.style.marginTop = 0;
    });
  };
  const handleWindowScroll = () => {
    const currentScrollPos = getCurrentScrollPosition();
    if (
      scrollOfset < currentScrollPos &&
      !document.querySelector(".navbar-collapse").classList.contains("show")
    ) {
      hideDefaultHeader();
    } else {
      showDefaultHeader();
    }
    scrollOfset = currentScrollPos;
  };
  window.addEventListener("resize", () => {
    if (isMobile()) {
      window.addEventListener("scroll", handleWindowScroll);
    } else {
      window.removeEventListener("scroll", handleWindowScroll);
    }
  });
  if (isMobile()) {
    window.addEventListener("scroll", handleWindowScroll);
  }
};

function initJSForm(form) {
  form.addEventListener("submit", function (event) {
    event.preventDefault();
    fetch("/form-submissions/", {
      method: "POST",
      body: new FormData(event.target),
    })
      .then((response) => {
        if (response.redirected) {
          return new Promise((resolve) => {
            resolve({ status: 302, url: response.url });
          });
        }
        if (response.status === 200 || response.status === 400) {
          return response
            .text()
            .then((content) => ({ status: response.status, content }));
        }
        throw Error("Server error!");
      })
      .then(
        (response) => {
          if (response.status === 302) {
            window.top.location.href = response.url;
            return;
          }
          form.outerHTML = response.content;
          if (response.status === 400) {
            initForms();
          }
        },
        () => {
          document.querySelector("#server-error").classList.remove("d-none");
        },
      );
  });
}

export const initForms = () => {
  document.querySelectorAll(".js-form").forEach(initJSForm);
  document.querySelectorAll(".form").forEach((form) => {
    form.addEventListener("submit", function (event) {
      const submitter = document.querySelector(
        `button[form="${event.currentTarget.id}"]`,
      );
      submitter.disabled = true;
      submitter.querySelector(".submit-loader").classList.remove("d-none");
      submitter.querySelector(".submit-text").classList.add("opacity-0");
    });
  });
  initPhones();
};

const initCarouselSelect = () => {
  document.querySelectorAll(".carousel-select").forEach((carouselEl) => {
    carouselEl
      .querySelectorAll(".carousel-select-control-prev")
      .forEach((control) => {
        control.addEventListener("click", () => {
          const current = carouselEl.querySelector(
            ".carousel-select-item.-active",
          );
          if (current) {
            const next = current.previousElementSibling;
            current.classList.add("passing-when-prev");
            next.classList.add("-active");
            next.classList.toggle("coming-when-prev");
          }
        });
      });
    carouselEl
      .querySelectorAll(".carousel-select-control-next")
      .forEach((control) => {
        control.addEventListener("click", () => {
          const current = carouselEl.querySelector(
            ".carousel-select-item.-active",
          );
          if (current) {
            const next = current.nextElementSibling;
            current.classList.add("passing-when-next");
            next.classList.add("-active");
            next.classList.toggle("coming-when-next");
          }
        });
      });
    carouselEl
      .querySelectorAll(".carousel-select-item")
      .forEach((carouselItem) => {
        carouselItem.addEventListener("animationend", (event) => {
          if (event.animationName === "passing-when-next-animation") {
            event.currentTarget.classList.remove("-active");
            event.currentTarget.classList.remove("passing-when-next");
          }
          if (event.animationName === "coming-when-next-animation") {
            event.currentTarget.classList.remove("coming-when-next");
          }
          if (event.animationName === "passing-when-prev-animation") {
            event.currentTarget.classList.remove("-active");
            event.currentTarget.classList.remove("passing-when-prev");
          }
          if (event.animationName === "coming-when-prev-animation") {
            event.currentTarget.classList.remove("coming-when-prev");
          }
        });
      });
  });
};

const init = () => {
  // initSentry();
  initForms();
  initBlocks();
  initModals();
  initHideOnScrolling();
  initCarouselSelect();
};

if (document.readyState !== "loading") {
  init();
} else {
  document.addEventListener("DOMContentLoaded", init);
}
